@import "./variable.scss";
.count {
    flex: 1 1 50px;

    &:hover {
        background-color: $lightgrey;
    }
}

.number {
    font-size: 28px;
    font-weight: 900;
    padding: calc($padding/2) $padding;
}

.warningIcon {
    &.inactive {
        opacity: 0.5;
        filter: grayscale(100%);
    }
}

.label {
    padding: 0px $padding $padding $padding;
}