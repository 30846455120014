@import "./variable.scss";
:root {
  --safe-area-inset-bottom: 0px;

  @supports (bottom: constant(safe-area-inset-bottom)) {
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  }

  @supports (bottom: env(safe-area-inset-bottom)) {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
  }
}
.summaryHolder {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;

  /*bottom: unquote("max(25px, var(--safe-area-inset-bottom))");
    left: env(safe-area-inset-left);
    right: env(safe-area-inset-right);*/
}

.summary {
  position: absolute;
  background: white;
  /*margin-left: $padding;
    margin-bottom: $padding;*/
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  box-sizing: content-box;
  height: $summaryHeight;
  height: calc(#{$summaryHeight} + env(safe-area-inset-bottom));

  bottom: 0px;
  left: 0px;
  /*width: calc(100% - #{$padding * 2});*/
  width: 400px;
  display: flex;

  .bar {
    margin-top: $padding;
    margin-bottom: $padding;
    width: 1px;
    box-sizing: border-box;
    background-color: $lightgrey;
    flex: none;
  }

  .menuIcon {
    font-size: 24px;
    padding: $padding * 1.5;
    flex: none;
    color: $grey;
    cursor: pointer;

    &.pale {
      color: $primaryHover;
    }

    &:hover {
      color: $primary;
    }
  }
}

@media only screen and (max-width: $smallSize) {
  .summary {
    width: 100%;
  }
}
