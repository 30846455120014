.list .item h2 {
  font-weight: 700;
  font-size: 18px;
}

.list .item h1,
.list .item h2,
.list .item h3,
.list .item h4,
.list .item p {
  margin: 0px;
  padding-right: 10px;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.list .item.clickable {
  cursor: pointer;
}

.list {
  text-align: left;
}

.menu {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 400px;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  background-color: #fff;
  color: #000;
  /* overflow: auto; */
}

#floodalert {
  font-family: Jacobs Chronos, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
}
list .item.header {
  background-color: #f6f6f6;
}

.list .item.sticky2 {
  position: sticky;
  top: 0px;
}
.list .item {
  display: flex;
  align-items: center;
  padding: 10px;
}
div {
  display: block;
}
.list {
  text-align: left;
}
.menu {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 400px;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  background-color: #fff;
  color: #000;
  /* overflow: auto; */
}
.list .item img {
  height: 48px;
  margin-right: 10px;
}
// img {
//   height: 48px;
//   margin-right: 10px;
// }
.listview .scrollable {
  flex: 1 1 auto;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  overflow: auto;
  overflow-x: auto;
  overflow-y: auto;
  
}

.scrollable {
  flex: 1 1 auto;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  overflow: auto;
  overflow-x: auto;
  overflow-y: auto;
}
.list.sticky {
  position: sticky;
  top: 60px;
  top: calc(60px + env(safe-area-inset-top));
  z-index: 2;
}
