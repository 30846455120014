@import "./variable.scss";

.home{
  position: fixed;
  top: 0px;
  left: 0px;

  width: 20px;
  font-size: 24px;
    padding: $padding * 1.5;
    flex: none;
    background-color: $primary;
    color: $white;
    cursor: pointer;

    &:hover {
      background-color: $primaryHover;
    }

    &.pale {
      background-color: $lightgrey;
      color: $primaryHover;

      &:hover {
        color: $white;
      }
    }

}

.searchHolder {
  position: fixed;
  top: 0px;
  left: 30px;
  /*top: env(safe-area-inset-top);
		left: env(safe-area-inset-left);
		right: env(safe-area-inset-right);*/
}

.search {
  position: fixed;
  /*margin-left: $padding;
		margin-top: $padding;*/
  top: 0px;
  left: 40px;
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  /*width: calc(100% - #{$padding * 2});
		max-width: 400px;*/
  //width: 400px;
  width: 360px;

  display: flex;
  align-items: stretch;

  .bar {
    margin-top: $padding;
    margin-bottom: $padding;
    width: 0px;
    box-sizing: border-box;
    background-color: $lightgrey;
    flex: none;
  }

  input[type="text"] {
    flex: 1 1 auto;
    padding: $padding;
    font-family: Jacobs Chronos, Helvetica, Arial, sans-serif;
    font-size: 18px;
    border: 0px;
    border-radius: 0px;
    margin: 0px;
    -webkit-appearance: none;
    /*width: 50px;*/
    color: $primary;
    background-color: $lightgrey;
  }

  .menuIcon {
    font-size: 24px;
    padding: $padding * 1.5;
    flex: none;
    background-color: $primary;
    color: $white;
    cursor: pointer;

    &:hover {
      background-color: $primaryHover;
    }

    &.pale {
      background-color: $lightgrey;
      color: $primaryHover;

      &:hover {
        color: $white;
      }
    }
  }
}

.results {
  position: absolute;
  top: 0px;
  left: 0px;
  /*margin-left: $padding;
		margin-top: $padding;*/
  padding-top: 41px + $padding * 2;
  padding-top: calc(#{41px + $padding * 2} + env(safe-area-inset-top));
  /*width: calc(100% - #{$padding * 2});
		max-width: 400px;*/
  width: 400px;
  overflow: hidden;
  margin-bottom: $summaryHeight;
  text-align: left;

  .result {
    display: flex;
    padding: $padding/2 $padding;
    cursor: pointer;

    &:hover {
      background-color: $lightgrey;
    }

    &:last-child {
      border-radius: 0px 0px $radius $radius;
    }

    h3,
    h4 {
      margin: 0;
    }

    h3 {
      color: #333333;
    }

    h4 {
      color: #a5a5a5;
    }

    .resultIcon {
      font-size: 32px;
      margin-right: $padding/2;
      color: $primary;
    }

    .resultText {
    }
  }
}

@media only screen and (max-width: $smallSize) {
  .searchHolder {
    right: 0px;
  }

  .search {
    width: 90%;
  }

  .results {
    width: 100%;
  }
}
