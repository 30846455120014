$radius: 0px;

$padding: 10px;
$primary: #0d7cff;
$primaryHover: #43a1ff;
$grey: #000;
$greyHover: #222;
$lightgrey: #f6f6f6;
$white: #fff;

$summaryHeight: 156px;

$smallSize: 600px;