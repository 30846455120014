@import "./variable.scss";

	:root {
		--safe-area-inset-bottom: 0px;

		@supports (bottom: constant(safe-area-inset-bottom)) {
			--safe-area-inset-bottom: constant(safe-area-inset-bottom);
		}

		@supports (bottom: env(safe-area-inset-bottom)) {
			--safe-area-inset-bottom: env(safe-area-inset-bottom);
		}
	}

	.snippet {
		//position: absolute; // Make position as fixed as snippet was hiding in iPad.
		position: fixed;
		/*margin-left: $padding;
		margin-bottom: $padding;*/
		padding-left: env(safe-area-inset-left);
		padding-right: env(safe-area-inset-right);
		box-sizing: content-box;
		//height: $summaryHeight - 80px;
		//height: calc(#{$summaryHeight} + env(safe-area-inset-bottom));
       // height: calc(#{$summaryHeight} --safe-area-inset-bottom );
		//height: calc(#{$summaryHeight} -- env(safe-area-inset-bottom)); // Still issue with this
        /*bottom: 36px + 60px + 20px + calc($padding*3);

		bottom: 0px;*/
		bottom: calc(#{$summaryHeight} + env(safe-area-inset-bottom));
		border-bottom: 1px solid $lightgrey;
		left: 0px;
		/*width: calc(100% - #{$padding * 2});*/
		width: 400px;
		display: flex;

		.item {
			background-color: $white;
			border-bottom: 1px solid $lightgrey;
		}

	}

	@media only screen and (max-width: $smallSize) {
		.snippet {
			width: 100%;
			//top:auto
			//height: --safe-area-inset-bottom
			//height:calc(#{$summaryHeight} + 1px);
			//height:max-content;
			//bottom: calc(#{$summaryHeight} + 1px);	
			/////height:calc(#{$summaryHeight} + 10px); ----- for iPhone
			position: fixed;
			
		}
	}

	.list-move, /* apply transition to moving elements */
	.list-enter-active,
	.list-leave-active {
		transition: all 0.5s ease;
	}

	.list-enter-from,
	.list-leave-to {
		opacity: 0;
		transform: translateY(100px);
	}

	/* ensure leaving items are taken out of layout flow so that moving
	animations can be calculated correctly. */
	.list-leave-active {
		position: absolute;
	}