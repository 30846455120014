@import "./variable.scss";

	.menuback {
		position: fixed;
		top: 0px;
		left: 0px;
		width: 400px;
		bottom: 0px;
		/*backdrop-filter: blur(5px);
		background-color: rgba(0, 0, 0, 0.3);*/
		cursor: pointer;

		.close {
			position: absolute;
			top: 0px;
			right: 0px;
			padding: $padding $padding/2;
			color: white;
			font-size: 24px;
			width: 30px;
		}

		&.sideonly {
			max-width: 400px + $padding * 2;
		}
	}

	@media only screen and (min-width: 500px) {
		.menuback {
			&:not(.sideonly) {
				display: none;
			}
		}
	}

	.menu {
		position: fixed;
		top: 0px;
		left: 0px;
		/*width: 400px;
		max-width: calc(100% - #{30px + $padding});*/
		width: 400px;

		/*width: 100%;
		max-width: 400px + $padding*2;*/

		height: 100%;
		box-sizing: border-box;

		padding-bottom: env(safe-area-inset-bottom);
		padding-left: env(safe-area-inset-left);
		background-color: $white;
		color: $grey;
		/*-webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
		-moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
		box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);*/

		h1 {
			margin: $padding 0px;
		}

		/*overflow: auto;*/
	}

	@media only screen and (max-width: $smallSize) {
		.menu {
			width: 100%;
		}
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s ease;
	}

	.fade-leave-active {
		/*transition-delay: 0.3s;*/
	}

	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}

	.slide-fade-enter-active {
		transition: all 0.5s ease;
		/*transition-delay: 0.3s;*/
	}
	.slide-fade-leave-active {
		transition: all 0.5s ease;
	}
	.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
		transform: translateX(-300px);
		opacity: 0;
	}

	.slide-left-enter-active,
	.slide-right-enter-active {
		/*transition-delay: 0.5s;*/
		transition: all 0.5s ease;
	}
	.slide-left-leave-active,
	.slide-right-leave-active {
		transition: all 0.5s ease;
	}
	.slide-left-enter, .slide-left-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
		transform: translateX(-300px);
		opacity: 0;
	}

	.slide-right-enter, .slide-right-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
		transform: translateX(300px);
		opacity: 0;
	}