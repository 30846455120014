@import "./variable.scss";
.intro {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;

  .introbox {
    position: relative;
    width: 100%;
    height: calc(100% - 80px);
    padding-top: env(safe-area-inset-top);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: center;

    .logo {
      width: 300px;
      max-width: calc(100% - 80px);
      margin-left: auto;
      margin-right: auto;
    }

    img {
      margin-bottom: $padding * 2;
      box-sizing: border-box;
      width: 100%;
      max-height: 100%;
      padding: $padding;

      &.width {
        width: 300px;
        max-width: calc(100% - 80px);
        padding: 0px;
      }
    }

    .pages {
      flex: 1 1 auto;
      border-radius: 10px;
      position: relative;

      .page {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        transition: 0.5s;

        .content {
          flex: 1 1 100%;
          width: 300px;
          height: 200px;
          max-width: calc(100% - 80px);

          &.full {
            width: 100%;
            max-width: 100%;
          }
          .rotated {
            transform: rotate(180deg); /* Equal to rotateZ(45deg) */
          }
          .swooshImg {
            transform: rotate(360deg); /* Equal to rotateZ(45deg) */
          }
        }

        .button {
          flex: 0 0 auto;
          margin-top: $padding;
          background-color: $primary;
          color: $white;
          padding: $padding;
          cursor: pointer;
          width: 300px;
          max-width: calc(100% - 80px);
          border: 2px solid $primary;

          &.secondary {
            background-color: transparent;
            color: $primary;
          }

          &:hover {
            background-color: $primaryHover;
            color: $white;
            border-color: $primaryHover;
          }

          &.hidden {
            opacity: 0;
          }
        }

        .icon {
          color: $primary;
          font-size: 128px;
        }
      }
    }
  }
}
