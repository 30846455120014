@font-face {
  font-family: Jacobs Chronos;
  src: local("../assets/fonts/JacobsChronos_W_Bd.woff2") format("woff2"),
    local("../assets/fonts/JacobsChronos_W_Lt.04ee7329.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Jacobs Chronos;
  src: local("../assets/fonts/JacobsChronos_W_Rg.1b02126a.woff2")
      format("woff2"),
    local("../assets/fonts/JacobsChronos_W_Rg.b63b0ce9.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Jacobs Chronos;
  src: local("../assets/fonts/JacobsChronos_W_It.4114e30a.woff2")
      format("woff2"),
    local("../assets/fonts/JacobsChronos_W_It.1a2ac6e9.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: Jacobs Chronos;
  src: local("../assets/fonts/JacobsChronos_W_Bd.4405d209.woff2")
      format("woff2"),
    local("../assets/fonts/JacobsChronos_W_Bd.21893608.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
// @font-face {
//   font-family: Jacobs Chronos;
//   src: local("../assets/fonts/JacobsChronos_W_Bd.woff2") format("woff2");
// }
body,
html {
  height: 100vh;
  margin: 0;
}
#floodalert {
  font-family: Jacobs Chronos, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
}
a {
  color: #0d7cff;
}
.mapboxgl-map {
  font-family: Jacobs Chronos, Helvetica, Arial, sans-serif !important;
}

/*// Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
  @supports (padding-top: constant(safe-area-inset-top)) {
    #floodalert {
      --safe-area-inset-top: constant(safe-area-inset-top);
      --safe-area-inset-bottom: constant(safe-area-inset-bottom);
      height: calc(100% + var(--safe-area-inset-top) + var(--safe-area-inset-bottom));
  
      --safe-area-inset-left: constant(safe-area-inset-left);
      --safe-area-inset-right: constant(safe-area-inset-right);
      width: calc(100% + var(--safe-area-inset-left) + var(--safe-area-inset-right));
    }
  }
  // Browsers which fully support CSS Environment variables (iOS 11.2+).
  @supports (padding-top: env(safe-area-inset-top)) {
    #floodalert {
      --safe-area-inset-top: env(safe-area-inset-top);
      --safe-area-inset-bottom: env(safe-area-inset-bottom);
      height: calc(100% + var(--safe-area-inset-top) + var(--safe-area-inset-bottom));
  
      --safe-area-inset-left: env(safe-area-inset-left);
      --safe-area-inset-right: env(safe-area-inset-right);
      width: calc(100% + var(--safe-area-inset-left) + var(--safe-area-inset-right));
    }
  }*/
.panel {
  border-radius: 0px;
  /*-webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);*/
  background-color: white;
}
.topblock {
  background-color: #0d7cff;
  height: 60px;
  padding-top: env(safe-area-inset-top);
  position: sticky;
  top: 0px;
  z-index: 2;
}
.listview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.listview .fixed {
  flex: none;
}
.listview .scrollable {
  flex: 1 1 auto;
  overflow: auto;
}
.list {
  text-align: left;
}
.list.sticky {
  position: sticky;
  top: 60px;
  top: calc(60px + env(safe-area-inset-top));
  z-index: 2;
}
.list .item {
  display: flex;
  align-items: center;
  padding: 10px;
  /*border-bottom: 1px solid $lightgrey;*/
}
.list .item.sticky2 {
  position: sticky;
  /*top: 30px + $padding*3 + $padding*2 + 20px + $padding*2;
    top: calc(120px + env(safe-area-inset-top));*/
  top: 0px;
}
.list .item.noflow {
  display: block;
}
.list .item.clickable {
  cursor: pointer;
}
.list .item.clickable:hover {
  background-color: #f6f6f6;
}
.list .item:last-child {
  border-bottom: 0px;
}
.list .item img {
  height: 48px;
  margin-right: 10px;
}
.list .item h1,
.list .item h2,
.list .item h3,
.list .item h4,
.list .item p {
  margin: 0px;
  padding-right: 10px;
}
.list .item h1.advice,
.list .item h2.advice,
.list .item h3.advice,
.list .item h4.advice,
.list .item p.advice {
  color: #0d7cff;
}
.list .item h1 {
  font-weight: 300;
  font-size: 28px;
}
.list .item h2 {
  font-weight: 700;
  font-size: 18px;
}
.list .item h3 {
  font-weight: 700;
  font-size: 18px;
}
.list .item h3.advice {
  font-size: 20px;
}
.list .item.header {
  background-color: #f6f6f6;
}
.list .item.header.clickable:hover {
  background-color: #0d7cff;
}
.list .item.header.clickable:hover h2,
.list .item.header.clickable:hover h3 {
  color: #fff;
}
.list .item.header h2,
.list .item.header h3 {
  color: #000;
}
.list .item.header h2 {
  color: #000;
  font-weight: 300;
  font-size: 24px;
}
.list .item.header.inverse h2,
.list .item.header.inverse h3 {
  color: #fff;
}
.list .item.filter {
  height: 28px;
  background-color: #0d7cff;
}
.list .item.filter.clickable:hover {
  background-color: #43a1ff;
}
.list .item.filter h2,
.list .item.filter h3 {
  color: white;
}
.list .item.bottom {
  padding-bottom: calc(10px + env(safe-area-inset-bottom));
}
.list .item.filterlist {
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  align-items: flex-start;
}
.list .item.filterlist h2,
.list .item.filterlist h3,
.list .item.filterlist h4,
.list .item.filterlist p {
  color: #0d7cff;
  cursor: pointer;
}
.list .item.filterlist h2:hover,
.list .item.filterlist h3:hover,
.list .item.filterlist h4:hover,
.list .item.filterlist p:hover {
  color: #43a1ff;
}
.list .item.filterlist h4 {
  margin-top: 10px;
}
.list .item.filterlist .itembox {
  flex: 1 1 auto;
}
.bottomBlock {
  height: env(safe-area-inset-bottom);
}
.marker {
  /*background-image: url('mapbox-icon.png');*/
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.resultmarker,
.locationmarker {
  /*background-image: url('mapbox-icon.png');*/
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #0d7cff;
  cursor: pointer;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: center;
  flex: 0 0 auto;
}
.locationmarker {
  /*background-image: url('mapbox-icon.png');*/
  width: 24px;
  height: 24px;
  background-color: #fff;
  color: #0d7cff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotating {
  animation: rotating 2s linear infinite;
}
.fade-intro-enter-active,
.fade-intro-leave-active {
  transition: opacity 0.5s ease;
}
.fade-intro-leave-active {
  /*transition-delay: 0.3s;*/
}
.fade-intro-enter,
.fade-intro-leave-to {
  opacity: 0;
}
