@import "./variable.scss";

.home {
  position: fixed;
  top: 0px;
  left: 0px;

  width: 20px;
  font-size: 24px;
  padding: $padding * 1.5;
  flex: none;
  background-color: $primary;
  color: $white;
  cursor: pointer;

  &:hover {
    background-color: $primaryHover;
  }

  &.pale {
    background-color: $lightgrey;
    color: $primaryHover;

    &:hover {
      color: $white;
    }
  }
}

.map {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  /*.mapboxgl-ctrl-top-left {
		top: calc(#{$padding*2 + 61px} + env(safe-area-inset-top));
	}*/
}

@media only screen and (max-width: $smallSize) {
  .map {
    bottom: calc(#{$summaryHeight} + env(safe-area-inset-bottom));
    top: calc(61px + env(safe-area-inset-top));
  }
}

.mapboxgl-canvas {
  background-color: #a9ddef;
}

.hiddenicon {
  display: none;
}

.mapboxgl-popup {
  .hiddenicon {
    display: block;
  }
}

.mapboxgl-popup-close-button {
  font-size: 200%;
  color: $primary;
}

.mapboxgl-popup-content {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 0px !important;
  pointer-events: auto;
  position: relative;
}
.marker1 {
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s ease; /* Add a transition for a smooth effect */
}

.marker1 .clicked {
  width: 80px;
  height: 80px;
}

.loader {
  width: 50px;
  --b: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #514b82;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,#000 1deg 70deg,#0000 71deg 90deg),
    radial-gradient(farthest-side,#0000 calc(100% - var(--b) - 1px),#000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
          mask-composite: intersect;
  animation: l5 1s infinite;
  
}
@keyframes l5 {to{transform: rotate(.5turn)}}
.center-screen {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 35vh;
  
}

.hide{
  display: none;
}
.sidebar {
    background-color: rgb(35 55 75 / 90%);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 100;
    left: 200;
    margin: 12px;
    border-radius: 4px;
  }
  
