@import "./variable.scss";

.inputHolder {
    position: relative;
    border-radius: $radius;
    border: 1px solid $primary;

    input[type=text] {
        box-sizing: border-box;
        width: 100%;
        border: 0;
        font-size: 16px;
        padding: $padding;
        background: transparent;
    }

    .selectedItems {
        padding: calc($padding/2);
        margin-top: calc($padding/2);
        margin-left: calc($padding/2);
        background-color: $primary;
        color: $white;
        border-radius: calc($radius/2);
        display: inline-block;
        cursor: pointer;
        &:hover {
            background-color: $primaryHover;
        }
    }

    .cancelSearch {
        position: absolute;
        bottom: $padding;
        right: $padding;

        &:hover {
            cursor: pointer;
            color: $primary;
        }
    }
}

.listItem {
    padding: $padding calc($padding/2);
    &:hover {
        cursor: pointer;
        color: $primary;
    }
}